import React, { ReactElement, useEffect, useState } from 'react';

import './consulting-page.scss'
import PageSlider from '../../components/page-slider/page-slider';
import Product from '../../models/product';
import ProductService from '../../services/product-service';
import ProductLayout from '../../components/product-layout/product-layout';
import SurveyAgreementDialog from '../../components/survey-agreement-dialog/survey-agreement-dialog';
import ConsultingActionButtons from '../../components/consulting-action-button/consulting-action-button';
import { useNavigate } from 'react-router-dom';

export default function ConsultingPage(): ReactElement {
    const navigate = useNavigate();
    const [ consultingAgreementOpened, setConsultingAgreementOpened] = useState<boolean>(false);
    const [ products, setProducts ] = useState<Product[]>([]);

    useEffect(() => {
        const service = new ProductService();
        service.list().then(result => {
            setProducts(result);
        });
    }, []);

    function onOpenConsultingButtonPressed() {
        setConsultingAgreementOpened(true);
    }

    function onAgreed() {
        setConsultingAgreementOpened(false);
        navigate('/survey?from=consulting')
    }

    function onCanceled() {
        setConsultingAgreementOpened(false);
    }

    return (
        <div className={'consulting-page'}>
            <PageSlider>{products.map(page => <ProductLayout key={page.id} data={page} isConsultingMode={true}/>)}</PageSlider>

            <ConsultingActionButtons onPressed={onOpenConsultingButtonPressed}></ConsultingActionButtons>
            <SurveyAgreementDialog opened={consultingAgreementOpened} onAgreed={onAgreed} onCanceled={onCanceled}/>
        </div>

    );
}
