import { ReactElement, useEffect, useState } from 'react';
import ResultService from '../../services/result-service';
import { Result } from '../../models/result';
import SurveyResultLayout from '../../components/survey-result-layout/survey-result-layout';

import './result-page.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function ResultPage(): ReactElement {
    const navigate = useNavigate();
    const params: any = useParams();
    const location = useLocation();
    const [ result, setResult ] = useState<Result>();

    function onClosed(from: string) {
        if (from === 'product') {
            navigate('/product');
        }
        else if (from === 'skin') {
            navigate('/skin');
        }
        else {
            navigate('/product');
        }
    }

    useEffect(() => {
        async function load() {
            const service = new ResultService();
            setResult(await service.getType(params.type.toUpperCase()));
        }

        load();
    }, [ params ]);

    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');

    return (
        <div className={'service-result'}>
            {
                result && <SurveyResultLayout result={result!}
                                              closable={from !== null}
                                              onClosed={from !== null ? () => onClosed(from!) : undefined}/>
            }
        </div>
    );
}
