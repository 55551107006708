import React, { ReactElement, useEffect, useState } from 'react';

import './skin-page.scss'
import SkinService from '../../services/skin-service';
import Skin from '../../models/skin';
import SkinLayout from '../../components/skin-layout/skin-layout';
import PageSlider from '../../components/page-slider/page-slider';
import ConsultingActionButtons from '../../components/consulting-action-button/consulting-action-button';
import SurveyAgreementDialog from '../../components/survey-agreement-dialog/survey-agreement-dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import ConsultingNoticeButtons from '../../components/consulting-notice-button/consulting-notice-button';
import ConsultingNoticeDialog from '../../components/consulting-notice-dialog/consulting-notice-dialog';

export default function SkinPage(): ReactElement {
    const navigate = useNavigate();
    const location = useLocation();
    const [ consultingAgreementOpened, setConsultingAgreementOpened ] = useState<boolean>(false);
    const [ pages, setPages ] = useState<Skin[]>([]);

    useEffect(() => {
        const service = new SkinService();
        service.list().then(pages => {
            setPages(pages);
        });
    }, []);

    function onOpenConsultingButtonPressed() {
        setConsultingAgreementOpened(true);
    }

    function onAgreed() {
        setConsultingAgreementOpened(false);
        navigate('/survey?from=skin')
    }

    function onCanceled() {
        setConsultingAgreementOpened(false);
    }

    const queryParams = new URLSearchParams(location.search);
    const c = queryParams.get('c') === 'true'

    return (
        <div className={'skin-page'}>
            <PageSlider>{pages.map(page => <SkinLayout key={page.id} data={page} isConsultingMode={c}/>)}</PageSlider>

            {c && <ConsultingActionButtons onPressed={onOpenConsultingButtonPressed}></ConsultingActionButtons>}
            {c && <SurveyAgreementDialog opened={consultingAgreementOpened} onAgreed={onAgreed} onCanceled={onCanceled}/>}
            {!c && <ConsultingNoticeButtons onPressed={onOpenConsultingButtonPressed}></ConsultingNoticeButtons>}
            {!c && <ConsultingNoticeDialog opened={consultingAgreementOpened} onClosed={onCanceled}/>}
        </div>

    );
}
