import { Question } from '../../../models/question';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Button, FormControl, RadioGroup } from '@mui/material';
import ConsultingCheckBox from '../../../components/consulting-check-box/consulting-check-box';
import React, { useEffect, useState } from 'react';

import './survey-form.scss'

import LeftArrow from '../../../images/left-arrow.png'
import RightArrow from '../../../images/right-arrow.png'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 7,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#FFFFFFAD',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 7,
        backgroundColor: '#E1D8CA;',
    },
}));

export interface SurveyFormProps {
    question: Question;
    selected?: number;
    onAnswered: (i: number) => void;
    onCanceled: () => void;
    currentStep: number;
    maxStepCount: number;
}

export default function SurveyForm(props: SurveyFormProps) {
    const [ selectedAnswer, setSelectedAnswer ] = useState<number | null>(props.selected ?? null);

    useEffect(() => {
        setSelectedAnswer(props.selected ?? null);
    }, [ props ]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAnswer(parseInt(event.target.value));
    };

    function onNextPressed() {
        props.onAnswered(selectedAnswer!);
    }

    function onPrevPressed() {
        props.onCanceled();
    }

    return (
        <div className={'survey-form'}>
            <div className={'survey-form-title'}>{props.question.title}</div>
            <div className={'survey-form-progress'}>
                <BorderLinearProgress variant="determinate" value={(props.currentStep / props.maxStepCount) * 100.0}/>
            </div>
            <div className={'survey-form-description'}
                 dangerouslySetInnerHTML={{ __html: props.question.description }}/>

            <FormControl fullWidth={true}>
                <RadioGroup onChange={handleChange} defaultValue={props.selected} value={selectedAnswer}>
                    <div className={'survey-form-items'}>
                        {
                            props.question.answers.map((a, i) =>
                                <div key={i} className={'survey-form-items-item'}>
                                    <ConsultingCheckBox title={a} value={i}/>
                                </div>
                            )
                        }
                    </div>
                </RadioGroup>
            </FormControl>

            <div className={'survey-form-action-bar'}>
                <Button className={'left'} onClick={onPrevPressed}>
                    <div className={'align-vcenter'}>
                        <img src={LeftArrow}
                             width={'9px'}
                             height={'20px'}
                             alt={'go to prev page'}
                             style={{ paddingRight: '7px' }}/>前
                    </div>
                </Button>
                <div className={'space'}></div>
                <Button className={'right'} onClick={onNextPressed} disabled={selectedAnswer === null}>
                    <div className={'align-vcenter'}>
                        次<img src={RightArrow}
                               width={'9px'}
                               height={'20px'}
                               alt={'go to prev page'}
                               style={{ paddingLeft: '7px' }}/>
                    </div>
                </Button>
            </div>
        </div>
    );
}
