import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import './result-form.scss'
import { Button } from '@mui/material';
import React from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 7,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#FFFFFFAD',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 7,
        backgroundColor: '#E1D8CA;',
    },
}));

export interface ResultFormProps {
    name: string;
    onSubmitted: () => void;
}

export default function ResultForm(props: ResultFormProps) {
    return (
        <div className={'result-form'}>
            <div className={'result-form-title'}>スキンレポート</div>
            <div className={'result-form-progress'}>
                <BorderLinearProgress variant="determinate" value={100.0}/>
            </div>
            <div className={'result-form-description'}>スキンレポートの作成を完了しました。</div>

            <div className={'result-form-content'}>
                <p>スキンレポートを提出して</p>
                <p>{props.name}さんの肌タイプに関するコンサルテーションを確認してください。</p>
                <p><br/></p>
                <p>SYRSのスキンコンサルタントが{props.name}さんの肌を分析し</p>
                <p>誠実さを込めたシンシアキットを提供いたします。</p>

                <div className={'result-form-content-notice'}>細やかなケアで改善される肌を体験してください。</div>
            </div>

            <div className={'result-form-action-bar'}>
                <div className={'space'}></div>
                <Button className={'right'} onClick={props.onSubmitted}>提出する</Button>
            </div>

        </div>
    );
}
