import { ReactElement, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle } from '@mui/material';

import './survey-agreement-dialog.scss'
import ConfigurationService from '../../services/configuration-service';
import ConsultingCheckBox from '../consulting-check-box/consulting-check-box';

interface SurveyAgreementDialogProps {
    opened: boolean;
    onAgreed: () => void;
    onCanceled: () => void;
}

export default function SurveyAgreementDialog(props: SurveyAgreementDialogProps): ReactElement {
    const [ text, setText ] = useState<string>('');

    function onAgreed(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            const interval = setInterval(() => {
                clearInterval(interval);
                props.onAgreed();
            }, 400);
        }
    }

    useEffect(() => {
        async function execute() {
            const service = new ConfigurationService();
            setText(await service.getPrivacyAgreementText());
        }

        execute();
    }, [ props ]);

    return (
        <div className={'survey-agreement-dialog'}>
            <Dialog open={props.opened}
                    onClose={() => props.onCanceled()}
            >
                <DialogTitle>SYRSプライバシーポリシー</DialogTitle>
                <DialogContent>
                    <div className={'notice-text'}>
                        <p>アンケート後、SYRS製品のニュースやスキンケアのヒントをメールで送信します。</p>
                        <p>このサービスのためにあなたの個人情報の使用と収集に同意しますか？</p>
                    </div>

                    <div className={'privacy-policy-text'} dangerouslySetInnerHTML={{ __html: text }}>

                    </div>
                    <div className={'agreement-text'}>
                        <ConsultingCheckBox title={'上記のすべてを読み、同意します。'} onChange={onAgreed}/>
                    </div>

                </DialogContent>

            </Dialog>
        </div>
    );
}
