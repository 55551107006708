import { ReactElement, useEffect, useState } from 'react';
import TextInputField from '../../../components/text-input-field/text-input-field';
import BirthDaySelector from '../../../components/birth-day-selector/birth-day-selector';

import './privacy-form.scss';
import { Button } from '@mui/material';
import KakaoTalkService from '../../../services/kakaotalk-service';

interface PrivacyFormProps {
    name?: string;
    phoneNumber?: string;
    birthDay?: Date;
    onEntered: (name: string, telNumber: string, birthDay: Date) => void;
}

export default function PrivacyForm(props: PrivacyFormProps): ReactElement {
    const [ disabled, setDisabled ] = useState<boolean>(false);
    const [ name, setName ] = useState<string | undefined>(props.name);
    const [ phoneNumber, setPhoneNumber ] = useState<string | undefined>(props.phoneNumber);
    const [ birthDay, setBirthDay ] = useState<Date | undefined>(props.birthDay);

    // function onBirthdaySelected(d: Date) {
    //     console.log(d);
    // }

    useEffect(() => {
        setName(props.name);
        setPhoneNumber(props.phoneNumber);
        setBirthDay(props.birthDay);
    }, [ props.birthDay, props.name, props.phoneNumber ]);

    useEffect(() => {
        setDisabled(!name || !phoneNumber || !birthDay);
    }, [ name, phoneNumber, birthDay ]);

    function onNext() {
        const service = new KakaoTalkService();
        // service.sendMessage('010-3380-0842', 'adad', 'a').then(() => {});
        props.onEntered(name!, phoneNumber!, birthDay!);
    }

    return (
        <div className={'privacy-form'}>
            <div className={'privacy-form-content'}>
                <div className={'privacy-form-content-title'}>One-on-one skin consultation</div>
                <div className={'privacy-form-content-description'}>
                    <div className={'privacy-form-content-description-text'}>
                        <p>肌の問題の根本的な改善のために、スキンコンサルタントとの1対1のスキンコンサルテーションを体験してください。</p>
                        <p>コンサルテーション後は、SYRSの最も効果的な製品5種が入ったシンシアキットをお届けします。</p>
                    </div>

                    <div className={'privacy-form-content-description-additional'}>
                        <p>* 診断に先立ち、お客様の携帯電話に診断結果を共有し、継続的なアフターケアを提供するために、名前、電話番号、生年月日の記入をお願いします。</p>
                        <p>それ以外の目的で使用されることはありません。</p>
                    </div>
                </div>

                <div className={'privacy-form-content-description-form'}>
                    <TextInputField label={'名前'}
                                    value={props.name}
                                    onEntered={(text: string) => setName(text)}/>

                    <TextInputField label={'メールアドレス'}
                                    type={'tel'}
                                    value={props.phoneNumber}
                                    onEntered={(text: string) => setPhoneNumber(text)}/>

                    <BirthDaySelector label={'生年月日'}
                                      value={props.birthDay ?? undefined}
                                      onSelected={(birthDay) => setBirthDay(birthDay)}/>
                </div>
            </div>
            <div className={'privacy-form-action-bar'}>
                <Button onClick={onNext} disabled={disabled}>アンケート開始</Button>
            </div>
        </div>
    );
}
