import React, { ReactElement, useState } from 'react';

import './survey-page.scss';
import SurveyForm from './survey-form/survey-form';
import { Question } from '../../models/question';
import PrivacyForm from './privacy-form/privacy-form';
import ResultForm from './result-form/result-form';
import SurveyHeader from '../../components/survey-header/survey-header';
import { CreatingAnswer, Score } from '../../models/answer';
import AnswerService from '../../services/answer-service';
import { useLocation, useNavigate } from 'react-router-dom';
import KakaoTalkService from '../../services/kakaotalk-service';


export default function SurveyPage(): ReactElement {
    const location = useLocation();
    const navigate = useNavigate();
    const [ step, setStep ] = useState<number>(0);
    const [ answer, setAnswer ] = useState<CreatingAnswer>({
        name: undefined,
        phoneNumber: undefined,
        birthDay: new Date(),
        createdDate: new Date(),
        choices: [],
        scores: [],
        resultType: undefined
    } as CreatingAnswer);

    const queryParams = new URLSearchParams(location.search);
    const from = queryParams.get('from');

    function renderItem(items: any[]): ReactElement {
        return items[step];
    }

    function onPrivacyInfoEntered(name: string, telNumber: string, birthDay: Date) {
        setAnswer({
            ...answer,
            name,
            phoneNumber: telNumber,
            birthDay
        });
        setStep(step + 1);
    }

    function onQuestionAnswered(questionIndex: number, answerIndex: number) {
        const a = {
            ...answer
        };
        a.choices[questionIndex] = answerIndex;
        setAnswer(a);
        setStep(step + 1)
    }

    function onSubmitted() {
        const scoreMapping = [ 4, 3, 2, 1 ];
        const t = answer.choices.map(c => scoreMapping[c]);
        const scores: Score[] = [
            { type: 'A', score: t[0] + t[1] },
            { type: 'B', score: t[2] + t[3] },
            { type: 'C', score: t[4] + t[5] },
            { type: 'D', score: t[6] + t[7] },
            { type: 'E', score: t[8] + t[9] }
        ];

        const sortedScores: Score[] = scores.sort((a: Score, b: Score): number => {
            const r = b.score - a.score;
            if (r !== 0) {
                return r;
            }

            return a.type.charCodeAt(0) - b.type.charCodeAt(0);
        });

        const result: CreatingAnswer = {
            ...answer,
            scores: sortedScores,
            resultType: sortedScores[0].type
        };

        const service = new AnswerService();
        service.write(result).then(() => {
            navigate(`/result/${result.resultType}` + (from ? `?from=${from}` : ''));
        });

        const kakaoTalkService = new KakaoTalkService();
        kakaoTalkService.sendMessage(answer.phoneNumber!, answer.name!, sortedScores[0].type).then(a => {
        });
    }

    const questions: Question[] = [
        {
            title: "Q1",
            description: "<p>お肌がどのような 刺激に赤く反応しますか？</p>",
            answers: [
                "環境の変化やわずかな 刺激にも容易に赤くなり、長く続く。",
                "些細な刺激にも容易に 赤くなるが、すぐに元の状態に戻る。",
                "引っ掻く行為のような 強い刺激があった場合のみ赤くなる。",
                "肌が赤くなる現象がほとんどない。"
            ]
        },
        {
            title: "Q2",
            description: "<p>お肌の質感はどうですか？</p>",
            answers: [
                "目で見て明らかに 肌が荒れている。",
                "触った時にのみ 荒れていると感じる。",
                "見た目には荒れていないが、触ると 荒れている感じがする。",
                "滑らかで柔らかい。"
            ]
        },
        {
            title: "Q3",
            description: "<p>洗顔直後の肌の状態はどうですか？</p>",
            answers: [
                "全体的にきつく引き締まる感じがする。",
                "頬や額などの広い部位が 微細に引き締まる感じがする。",
                "外見は問題ないが、 肌の内部に引き締まる感じがする。",
                "全体的に引き締まる感じが全くない。"
            ]
        },
        {
            title: "Q4",
            description: "<p>洗顔後の基礎製品を塗った肌の状態はどうですか？</p>",
            answers: [
                "塗った直後も乾燥感が続く。",
                "大丈夫なように見えるが、 すぐに水分を失い乾燥感がする。",
                "2~3時間程度は保湿感が持続する。",
                "一日中乾燥感がなく、柔らかく潤っている。"
            ]
        },
        {
            title: "Q5",
            description: "<p>顔をしかめたり笑ったりしたとき<br/>お肌のしわの状態はどうですか？</p>",
            answers: [
                "顔全体にしわが存在する。",
                "動きが多い目元や口元周辺にのみしわが存在する。",
                "部分的にしわが薄く目立つ。",
                "しわがほとんど見えない。"
            ]
        },
        {
            title: "Q6",
            description: "<p>表情を緩めたときの顔の肌のしわの状態はどうですか？</p>",
            answers: [
                "全体的にしわの跡が残る。",
                "動きが多い目元や口元周辺にの みしわの跡が残る。",
                "とても薄いしわの跡が一時的に 残るが、すぐに消える。",
                "跡形もなく滑らか。"
            ]
        },
        {
            title: "Q7",
            description: "<p>日常的な肌の状態はどうですか？</p>",
            answers: [
                "肌の内部は引き締まるが、 表面は脂っぽく光る。",
                "乾燥感がありながらも肌の 表面には油分が感じられる。",
                "わずかに乾燥感がある。",
                "乾燥感も油分も感じられない。"
            ]
        },
        {
            title: "Q8",
            description: "<p>平均的にトラブルが発生する周期はどのくらいですか？</p>",
            answers: [
                "数日ごとに頻繁に発生する。",
                "2~3週間に一度発生する。",
                "数ヶ月または数年に一度発生する。",
                "ほとんど発生しない。"
            ]
        },
        {
            title: "Q9",
            description: "<p>顔にそばかすやシミのような斑点が<br/>どれくらい多く発生しますか？</p>",
            answers: [
                "全体的に多く分布しており、 続けて発生する。",
                "長く持続しないが周期的に 新しい斑点が発生する。",
                "数年に一度、小さな斑点が発生する。",
                "目立つ斑点がなく、ほとんど発生しない。"
            ]
        },
        {
            title: "Q10",
            description: "<p>肌トラブルや傷が治った後<br/>茶色の跡がどれくらい続きますか？</p>",
            answers: [
                "6ヶ月〜1年以上",
                "1〜6ヶ月の間",
                "4週間以内",
                "発生しない。"
            ]
        }
        
    ];

    const items = [
        <PrivacyForm
            name={answer.name}
            phoneNumber={answer.phoneNumber}
            birthDay={answer.birthDay}
            onEntered={onPrivacyInfoEntered}/>,
        ...(questions.map((q, qi) =>
            <SurveyForm question={q}
                        selected={answer.choices[qi]}
                        onAnswered={(ai) => onQuestionAnswered(qi, ai)}
                        onCanceled={() => setStep(step - 1)}
                        maxStepCount={questions.length + 1}
                        currentStep={qi + 1}/>
        )),
        <ResultForm name={answer.name!} onSubmitted={onSubmitted}/>
    ]

    return (
        <div className={'survey-page'}>
            <SurveyHeader closable={true} onClosed={() => {
                navigate(`/${from}`);
            }}/>
            <div className={'survey-page-content'}>{renderItem(items)}</div>
        </div>
    );
}
