import { ReactElement } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import './solution-card.scss'
import { Solution } from '../../models/solution';

export interface SolutionCardProps {
    prefix: string;
    solution: Solution;
}

export default function SolutionCard(props: SolutionCardProps): ReactElement {
    const matches = useMediaQuery('(max-width: 768px)');

    function onClicked() {
        window.location.href = props.solution.link;
    }

    return (
        <div className={'solution-card'}
             style={{ backgroundImage: `url(${matches ? props.solution.mobileImageUrl : props.solution.imageUrl})` }}
             onClick={onClicked}
        >
            <div className={'solution-card-top-notice'}>{props.prefix}</div>
            <div className={'solution-card-title'}>
                <div className={'solution-card-title-text'}>{props.solution.title}</div>
                <div className={'solution-card-title-space'}></div>
                <p>{props.solution.volume}</p>
            </div>
            <div className={'solution-card-sub-title'}>{props.solution.subTitle}</div>
            <div className={'solution-card-sub-texts'}>
                {
                    props.solution.texts.map(text => <p key={text}>{text}</p>)
                }
            </div>
            <div className={'solution-card-bottom-notice'}>
                <p>* 原材料の特性に限定</p>
                <p>* GFC Life Scienceの研究結果</p>
            </div>
        </div>
    );
}
