import React, { ReactElement, useEffect, useState } from 'react';

import './product-page.scss'
import PageSlider from '../../components/page-slider/page-slider';
import Product from '../../models/product';
import ProductService from '../../services/product-service';
import ProductLayout from '../../components/product-layout/product-layout';
import ConsultingNoticeDialog from '../../components/consulting-notice-dialog/consulting-notice-dialog';
import ConsultingNoticeButtons from '../../components/consulting-notice-button/consulting-notice-button';

export default function ProductPage(): ReactElement {
    const [ consultingAgreementOpened, setConsultingAgreementOpened] = useState<boolean>(false);
    const [ products, setProducts ] = useState<Product[]>([]);

    useEffect(() => {
        const service = new ProductService();
        service.list().then(result => {
            setProducts(result);
        });
    }, []);

    function onOpenConsultingButtonPressed() {
        setConsultingAgreementOpened(true);
    }

    function onCanceled() {
        setConsultingAgreementOpened(false);
    }

    return (
        <div className={'product-page'}>
            <PageSlider>{products.map(page => <ProductLayout key={page.id} data={page} isConsultingMode={false}/>)}</PageSlider>

            <ConsultingNoticeButtons onPressed={onOpenConsultingButtonPressed}></ConsultingNoticeButtons>
            <ConsultingNoticeDialog opened={consultingAgreementOpened} onClosed={onCanceled}/>
        </div>

    );
}
