import { ReactElement } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function WelcomePage(): ReactElement {
    const navigate = useNavigate();
    return (
        <div>
            <Button onClick={() => navigate('/product')}>매대용</Button>
            <Button onClick={() => navigate('/consulting')}>상담용</Button>
        </div>
    )
}
