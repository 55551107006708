import Fab from "@mui/material/Fab";
import NotesMedical from '../../images/notes-medical.png'

import './consulting-action-button.scss'
import { Button, Popper } from '@mui/material';
import React from 'react';

import PopoverIcon from '../../images/popover.png'

export interface ConsultingActionButtonsProps {
    onPressed: () => void;
}

export default function ConsultingActionButtons(props: ConsultingActionButtonsProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const sxProps = {
        position: 'absolute',
        bottom: 45,
        right: 55
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    return (
        <div className={'consulting-action-button'}>
            <Fab aria-label="Add"
                 component="div"
                 sx={sxProps}
                 // onClick={props.onPressed}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                }}
            >
                <img src={NotesMedical} className={'consulting-action-button-image'} alt={'consulting action button'}/>
            </Fab>
            <Popper id={id} open={open} anchorEl={anchorEl} placement={'top'}
                    className={'consulting-action-button-popover'}
                    modifiers={[
                        {
                            name: "offset",
                            options: {
                                offset: [ -50, 10 ],
                            },
                        },
                    ]}>
                <div style={{ backgroundImage: `url(${PopoverIcon})`, backgroundSize: '100%', backgroundRepeat:  'no-repeat'}} >
                    <div className={'consulting-action-button-popover-text'}>
                    <Button className={'skin-report-button'} onClick={props.onPressed}>スキンレポート</Button>

                    </div>
                </div>
            </Popper>
        </div>
    );
}
